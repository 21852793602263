
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class PageTabs extends Vue {
  @Prop({
    default: 0,
  })
  public value!: number;

  @Prop({
    default() {
      return [];
    },
  })
  public tabs!: string[];

  @Prop({
    default: false,
  })
  public small!: boolean;

  @Prop({
    default() {
      return [];
    },
  })
  public links!: string[];

  public input(value: number) {
    this.$emit("input", value);
  }
}
